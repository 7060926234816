import React from "react"
import Header from "../components/header"
import { Helmet } from "react-helmet"

import Style from "../styles/content.module.css"

export default () => (
  <div>
    <Helmet>
      <title>Maximo Macchi - About Me</title>
    </Helmet>
    <Header />
    <div className={Style.gridContainer}>
      <div className={Style.contentGroup}>
        <div className={Style.contentHeader}>
          <h1 className={Style.contentTitle}>Who Am I?</h1>
        </div>
        <div className={Style.contentParagraph}>
          Hi! My name is Max and I live in Chicago. I am a Software Engineer
          specializing in front-end web development. My main area of focus
          currently is building responsive, accessible, and scalable React-based
          applications.
        </div>
      </div>

      <div className={Style.contentGroup}>
        <div className={Style.contentHeader}>
          <h1 className={Style.contentTitle}>My Skills</h1>
        </div>
        <div className={Style.contentParagraph}>
          <ul className={Style.contentList}>
            <li>JavaScript</li>
            <li>TypeScript</li>
            <li>React.js</li>
            <li>Redux</li>
            <li>AngularJS</li>
            <li>HTML</li>
            <li>Sass / CSS</li>
            <li>Python</li>
            <li>Node.js</li>
            <li>SQL</li>
            <li>REST APIs</li>
            <li>Bootstrap</li>
            <li>Version control</li>
            <li>Unit testing</li>
            <li>Continuous integration</li>
            <li>Responsive design</li>
          </ul>
        </div>
      </div>

      <div className={Style.contentGroup}>
        <div className={Style.contentHeader}>
          <h1 className={Style.contentTitle}>My Experience</h1>
        </div>

        <h2 className={Style.experienceLabel}>Albert</h2>
        <h3 className={Style.experienceLabel}>
          Software Engineer II // January 2022 - June 2022
        </h3>
        <div className={Style.contentParagraph}>
          <ul className={Style.contentList}>
            <li>
              Promoted to SE II level in my first performance review cycle. This
              level of promotion is rarely seen at Albert as most employees are
              typically not considered for promotion within the first year of
              employment
            </li>
            <li>
              Led QA initiatives for Albert's web application during initial
              development. This involved coordinating with product designers and
              team members to delegate work and verify bug fixes, leading to
              over 100 QA items being resolved within a two-month timeline
            </li>
            <li>
              Assigned as project owner for Albert's web style guide
              integration. The style guide's purpose is to have consistently
              defined styling for various UI components. Owning this project
              involved writing a 6-page PRD specifying requirements,
              implementation approach, estimated timelines, and trade-off
              decision analysis
            </li>
            <li>
              At a technical level, integrating the style guide consisted of
              refactoring over 15 commonly used UI components throughout the
              Albert web applications and verifying such changes with
              pixel-perfect accuracy against Figma design mockups. This was
              estimated to increase QA lifecycle efficiency by approximately 15%
            </li>
            <li>
              Contributed to overhaul of Albert's mobile device web signup flow,
              one of Albert's largest revenue channels. This consisted of
              refactoring the responsive UI for over 15 pages in the signup flow
              and testing such changes across multiple mobile and desktop
              devices
            </li>
          </ul>
        </div>
        <h3 className={Style.experienceLabel}>
          Software Engineer I // May 2021 - January 2022
        </h3>
        <div className={Style.contentParagraph}>
          <ul className={Style.contentList}>
            <li>
              Member of the Web team, building web applications for Albert
              products and services
            </li>
            <li>
              Specialized in front-end technologies such as React, Redux,
              TypeScript, styled-components, and Webpack
            </li>
            <li>
              Collaborated with the Product team, specifically product
              designers, to implement product mockups with pixel-perfect styling
            </li>
            <li>
              Within one month of joining the team, contributed to 25% of total
              team output for our first project deadline, resulting in a 50%
              increase in the team's overall output and setting a new company
              record for the Web team's output
            </li>
            <li>
              Contributed to redesign of Albert's website rebrand, building 4
              new pages and assisting teammates with building additional pages.
              The project included collaborating with product designers as well
              as investigating and integrating the Lever.co API for fetching
              Albert job board data
            </li>
            <li>
              Assigned as lead for building and testing the Albert Web app Home
              pages. Building these 10 new pages involved planning the
              engineering architecture approach, communicating with stakeholders
              to gather requirements, delegating work to teammates, updating my
              manager with the project's timeline and challenges, and
              contributing my own programming work including coding and pull
              request reviews. This resulted in creating a new point of access
              for Albert users to use Albert services
            </li>
          </ul>
        </div>
        <div className={Style.divider} />

        <h2 className={Style.experienceLabel}>Data Decisions</h2>
        <h3 className={Style.experienceLabel}>
          Software Engineer // February 2020 - April 2021
        </h3>
        <div className={Style.contentParagraph}>
          <ul className={Style.contentList}>
            <li>
              Built AngularJS applications to solve business needs of varying
              companies, mainly forklift manufacturers
            </li>
            <li>
              Main project was a sales team CRM which handles a sales pipeline,
              communication, and data for over 200 customer accounts
            </li>
            <li>
              Completed over 6 Jira issues on average weekly which included new
              features and bug fixes, resulting in an increase of the team's
              work output by 30%
            </li>
            <li>
              Within 7 months, built over 150 features into the sales team CRM
              collaborating with the executive assistant and CEO of a customer
              site; this included biweekly demos and using a support ticketing
              system to keep track of updates for the customer
            </li>
            <li>
              Created over 50 new REST API endpoints for our backend system
              leading to an increase in data read / write speeds by 10%
            </li>
            <li>
              Collaborating with president of Data Decisions on design decisions
              and customer support to produce products that satisfy our
              customers
            </li>
          </ul>
        </div>
        <div className={Style.divider} />

        <h2 className={Style.experienceLabel}>Cyclebar Culver City</h2>
        <h3 className={Style.experienceLabel}>
          Cyclebar Experience & Sales Associate // November 2019 - February 2020
        </h3>
        <div className={Style.contentParagraph}>
          <ul className={Style.contentList}>
            <li>
              Contributed to highly-rated Cyclebar customer satisfaction by
              assisting over 120 clients daily
            </li>
            <li>
              Managed multiple tasks in small time frames such as cleaning,
              making sales calls, and satisfying client requests all within 45
              minute to 1 hour periods
            </li>
            <li>
              Increased number of clients through membership sale pitches and
              offering packages based on clients' needs. Cyclebar Culver City
              has been ranked 2 years in a row as the #1 franchise for member
              count across over 200 Cyclebar franchise locations
            </li>
          </ul>
        </div>
        <div className={Style.divider} />

        <h2 className={Style.experienceLabel}>Freelance Web Developer</h2>
        <h3 className={Style.experienceLabel}>August 2019 - January 2020</h3>
        <div className={Style.contentParagraph}>
          <ul className={Style.contentList}>
            <li>
              Building websites and applications for various clients, mainly
              small businesses or independent entrepreneurs.
            </li>
            <li>
              Working with clients to define project requirements, establish
              deadlines, and estimate costs.
            </li>
            <li>
              Regularly presenting demos to clients to share progress on
              project, gain feedback, and suggest additional features.
            </li>
            <a
              className={Style.projectLink}
              href="https://impeccablewraps.com/"
            >
              <li>Impeccable Wraps Client </li>
            </a>
          </ul>
        </div>
        <div className={Style.divider} />

        <h2 className={Style.experienceLabel}>Vocera Communications</h2>
        <h3 className={Style.experienceLabel}>
          CO-OP Intern // May 2018 - December 2018
        </h3>
        <div className={Style.contentParagraph}>
          <ul className={Style.contentList}>
            <li>
              Member of Tools team under Support department. Tools team develops
              tools (mostly applications and scripts) used by the Support
              department. Tools used assist technical support engineers with
              closing customer support cases and developing their careers.
            </li>
            <li>
              Built 2 new full-stack web apps used by technical support
              engineers for career development. Collaborated with full-time
              engineers and other interns, delegating features to be built.
            </li>
            <li>
              Developed new features and resolved bugs with current applications
              maintained by our team. Worked with management to define
              requirements for new features as well as setting timelines for
              releases.
            </li>
            <li>
              Followed gitflow workflow for new features and hotfixes that were
              developed; fulfilled requests made through code reviews such as
              modifying code readability, optimizing performance, and adding new
              features.
            </li>
            <li>
              Contributed ideas towards planning and design meetings and
              actively participated in over 50 standup team meetings.
            </li>
            <li>
              Engineered over 30 pieces of documentation for added features to
              current applications, details of new applications developed by
              myself and others, app architectures and stacks, and guides.
            </li>
          </ul>
        </div>
        <div className={Style.divider} />

        <h2 className={Style.experienceLabel}>
          New Student and Family Programs, University of the Pacific
        </h2>
        <h3 className={Style.experienceLabel}>
          Scheduling and Coverage Manager // August 2017 - May 2018
        </h3>
        <div className={Style.contentParagraph}>
          <ul className={Style.contentList}>
            <li>
              Scheduled 28 employees to work welcome desk shifts with
              approximately 50 shifts assigned per week.
            </li>
            <li>
              Communicated with staff daily through emails; contributed towards
              monthly staff meetings by presenting scheduling updates to staff
              of 68 individuals.
            </li>
            <li>
              Assisted coordinators of New Student and Family Programs with
              various tasks such as contributing towards the design of the new
              peer mentor program.
            </li>
          </ul>
        </div>
        <div className={Style.divider} />

        <h2 className={Style.experienceLabel}>
          New Student and Family Programs, University of the Pacific
        </h2>
        <h3 className={Style.experienceLabel}>
          Pacific Ambassador // April 2016 - August 2017
        </h3>
        <div className={Style.contentParagraph}>
          <ul className={Style.contentList}>
            <li>
              Served as representative of University of the Pacific community.
            </li>
            <li>Hosted over 150 prospective families on campus tours.</li>
            <li>
              Served as orientation leader and welcomed in over 1,600 new
              students into Pacific.
            </li>
            <li>
              Mentored over 100 students in orientation groups as orientation
              leader.
            </li>
            <li>
              Student and family populations included multiple ethnicities,
              genders, religions, ages, and backgrounds.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
)
